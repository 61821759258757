import Manifest from './Manifest.vue'
import axios from 'axios'
import jwt_decode from "jwt-decode";
export default {
    component:{
        Manifest
    },
    data(){
        return {
            tabShow: "1",
            shipperType:'tel',
            consigneeType:'tel',
            consignee_contactType: 'tel',
            notifierType: "tel",
            carrierList:[],
            manifestMsg: {
                id: null,
                msg_status:'S',
                shipping_agency:'',//132208081船舶代理代码
                eb_no: '',//FYEB030673-59405订舱编号
                bl_no: '',//A56BX02909提单号
                carrier: '',//ONE船公司
                start_port_code: '',//CNSHA起运港代码
                start_port: '',//SHANGHAI起运港
                end_port_code: '',//MYPGU目的港代码
                end_port: '',//PASIR GUDANG目的港
                transfer_port_code: '',//MYPGU中转港代码
                transfer_port: '',//PORT KELANG中转港
                place_code_of_receipt: '',//CNSHA收货地代码
                place_of_receipt:'',//SHANGHAI收货地
                load_port_code: '',//CNSHA装货地代码
                load_port: '',//SHANGHAI装货地
                discharge_port_code: '',//MYPGU卸货港代码
                discharge_port: '',//PASIR GUDANG卸货地
                vessel: '',//CLEMENS SCHULTE船名
                voyage: '',//1124T航次
                put_type: '',//ORI放单类型
                freight_terms: '',//PP运费条款
                transport_terms: '',//CY-CY运输条款
                // 发
                shipper_name: '',//SHIMANO(KUNSHAN)BICYCLE COMPONENTS  CO.,LTD.
                shipper_addr: '',//NO.6 DONGTINGHU SOUTH ROAD KUNSHAN \nCITY JIANGSU PROVINCE CHINA 215335
                shipper_code: '',//913205836082759776
                shipper_country_code: '',//CN
                shipper_aeo: '',
                shipper_tel: '',//86-512-57310666
                shipper_fax: '',
                shipper_mail:'',
                // 收
                consignee_code: '',//9999+9999
                consignee_name: '',//SHIMANO COMPONENTS (M) SDN BHD
                consignee_addr: '',//LOT 4550, LORONG A-16, PEKAN \nNANAS,81500 PONTIAN, \nJOHOR, MALAYSIA
                consignee_tel: '',//607-6896355
                consignee_fax: '',
                consignee_mail:'',
                consignee_aeo: '',
                consignee_contact_name:'',
                consignee_country_code: '',//MY
                consignee_contact_tel: '',
                consignee_contact_email: '',
                consignee_contact_fax: '',
                 // 通
                 notifier_code: '',//9999+9999
                 notifier_name: '',//SANBO LOGISTICS (MALAYSIA) SDN BHD
                 notifier_addr: '',//LOT 1410, LORONG A-13 81500 PEKAN \nNENAS,PONTIAN,MALAYSIA \n
                 notifier_tel: '',//607-6991141
                 notifier_fax: '',
                 notifier_email:'',
                 notifier_country_code: '',//MY
                 cargos: [],
                 containers: []
            },
            rules: {
                // 基础信息
                shipping_agency: [{required: true, message: '请选择船舶代理代码', trigger: 'blur'}],
                eb_no: [{required: true, message: '请填写订舱编号', trigger: 'blur'}],
                bl_no: [{required: true, message: '请填写提单号', trigger: 'blur'}],
                carrier: [{required: true, message: '请填写船公司', trigger: 'blur'}],
                start_port_code: [{required: true, message: '请填写起运港代码', trigger: 'blur'}],
                start_port: [{required: true, message: '请填写起运港', trigger: 'blur'}],
                end_port_code: [{required: true, message: '请填写目的港代码', trigger: 'blur'}],
                end_port: [{required: true, message: '请填写目的港', trigger: 'blur'}],
                transfer_port_code: [{required: true, message: '请填写中转港代码', trigger: 'blur'}],
                transfer_port: [{required: true, message: '请填写中转港', trigger: 'blur'}],
                place_code_of_receipt: [{required: true, message: '请填写收货地代码', trigger: 'blur'}],
                place_of_receipt: [{required: true, message: '请填写收货地', trigger: 'blur'}],
                load_port_code: [{required: true, message: '请填写装货地代码', trigger: 'blur'}],
                load_port: [{required: true, message: '请填写装货地', trigger: 'blur'}],
                discharge_port_code: [{required: true, message: '请填写卸货地代码', trigger: 'blur'}],
                discharge_port: [{required: true, message: '请填写卸货地', trigger: 'blur'}],
                vessel: [{required: true, message: '请填写船名', trigger: 'blur'}],
                voyage: [{required: true, message: '请填写航次', trigger: 'blur'}],
                put_type: [{required: true, message: '请选择放单方式', trigger: 'blur'}],
                freight_terms: [{required: true, message: '请选择运费条款', trigger: 'blur'}],
                transport_terms: [{required: true, message: '请选择运输条款', trigger: 'blur'}],
                
                // 收发通
                // 发
                shipper_name: [{required: true, message: '请填写发货人', trigger: 'blur'}],
                shipper_addr: [
                    { required: true, message: '请填写发货人地址', trigger: 'blur' },
                    { max: 70, message: '最多可输入70个字符', trigger: 'blur' }
                ],
                shipper_code: [{required: true, message: '请填写企业代码', trigger: 'blur'}],
                shipper_country_code: [{required: true, message: '请填写发货人国家代码', trigger: 'blur'}],
                shipper_tel: [{required: true, message: '请填写电话', trigger: 'blur'}],
                shipper_fax: [{required: true, message: '请填写发货人传真', trigger: 'blur'}],
                shipper_mail: [{required: true, message: '请填写发货人邮箱', trigger: 'blur'}],
                // 收
                consignee_code: [{required: true, message: '请填写收货人代码', trigger: 'blur'}],
                consignee_name: [{required: true, message: '请填写收货人名称', trigger: 'blur'}],
                consignee_addr: [
                    { required: true, message: '请填写收货人地址', trigger: 'blur' },
                    { max: 70, message: '最多可输入70个字符', trigger: 'blur' }],
                consignee_tel: [{required: true, message: '请填写收货人电话', trigger: 'blur'}],
                consignee_fax: [{required: true, message: '请填写收货人传真', trigger: 'blur'}],
                consignee_mail: [{required: true, message: '请填写收货人邮箱', trigger: 'blur'}],
                consignee_country_code: [{required: true, message: '请填写收货人国家代码', trigger: 'blur'}],
                consignee_contact_tel: [{required: true, message: '请填写具体收货人电话', trigger: 'blur'}],
                consignee_contact_email: [{required: true, message: '请填写具体收货人邮箱', trigger: 'blur'}],
                consignee_contact_fax: [{required: true, message: '请填写具体收货人传真', trigger: 'blur'}],
                // 通
                notifier_code: [{required: true, message: '请填写企业代码', trigger: 'blur'}],
                notifier_name: [{required: true, message: '请填写通知人', trigger: 'blur'}],
                notifier_addr: [
                    { required: true, message: '请填写通知人地址', trigger: 'blur' },
                    { max: 70, message: '最多可输入70个字符', trigger: 'blur' }],
                notifier_tel: [{required: true, message: '请填写通知人电话', trigger: 'blur'}],
                notifier_fax: [{required: true, message: '请填写通知人传真', trigger: 'blur'}],
                notifier_email: [{required: true, message: '请填写通知人邮箱', trigger: 'blur'}],
                notifier_country_code: [{ required: true, message: '请填写国家代码', trigger: 'blur' }],
            },
            cargosItem: {
                cargo_type: "",// S货物标识/货物类型
                cargo_code: "",//货物代码123
                cargo_num: 0,//货物数量
                cargo_weight: 0,//货物毛重
                cargo_volume: 0,//货物体积
                cargo_pack: "",//包装类型PACKAGES
                cargo_name: "",//货物品名BICYCLE PARTS\nHS8714940000
                hscode: '',//hscode海关HS码12345678
                marks: "",//唛头NM
                packaging_tare: 0,//包装皮重123
                quarantine_code: '',//防疫代码1234
                quarantine_name: '',//防疫名称456
                danger_class: '',//危险品分类789
                danger_no: '',//危险品编号111
                danger_page: '',//危险品页号222
                danger_flashpoint: "",//危险品页号0
                packing_group: '',// 包装类别12345
                danger_phone: "",//危险品紧急联系人4567878
            },
            saveCargos:false,
            cargosItemVisible: false,
            saveCargosIdx:0,
            cargosItemRules: {
                cargo_type: [{ required: true, message: '请填写货物标识', trigger: 'blur' }],
                cargo_num: [{ required: true, message: '请填写货物数量', trigger: 'blur' }],
                cargo_weight: [{ required: true, message: '请填写货物毛重', trigger: 'blur' }],
                cargo_volume: [{ required: true, message: '请填写货物体积', trigger: 'blur' }],
                cargo_pack: [{ required: true, message: '请填写包装类型', trigger: 'blur' }],
                cargo_name: [{ required: true, message: '请填写货物品名', trigger: 'blur' }],
                marks: [{ required: true, message: '请填写唛头', trigger: 'blur' }],
                packaging_tare: [{ required: true, message: '请填写包装皮重', trigger: 'blur' }],
                danger_class: [{ required: true, message: '请填写危险品分类', trigger: 'blur' }],
                danger_no: [{ required: true, message: '请填写危险品编号', trigger: 'blur' }],
                danger_page: [{ required: true, message: '请填写危险品页号', trigger: 'blur' }],
                danger_flashpoint: [{ required: true, message: '请填写危险品闪点', trigger: 'blur' }],
            },
            container_soc_list: [
                {
                    label: "货主箱",
                    value: true
                  },
                  {
                    label: "非货主箱",
                    value: false
                  },
            ],
            containersItem: {
                container_type: "",// 箱型20GP
                container_mark: "",//集装箱标识F
                container_soc: "",// 货主箱标识true
                container_no: "",//箱号IAAU2761620
                seal_no: "",//铅封号IAAF630865
                cargo_num_per: '',//货物数量20
                cargo_weight_per:'',//货物重量 9732.88
                cargo_volume_per: '',// 货物体积9.15
                normal_freeze: "" //Y冷箱标志
            },
            containersItemVisible: false,
            saveContainers: false,
            saveContainersIdx:0,
            containersItemRules: {
                container_type: [{required: true, message: '请选择箱型', trigger: 'change' }],
                container_mark: [{required: true, message: '请选择集装箱标识', trigger: 'change' }],
                container_soc: [{required: true, message: '请选择货主箱标识', trigger: 'blur' }],
                container_no: [{required: true, message: '请填写箱号', trigger: 'blur' }],
                seal_no: [{required: true, message: '请填写铅封号', trigger: 'blur' }],
                cargo_num_per: [{ required: true, message: '请填写数量', trigger: 'blur' }],
                cargo_weight_per: [{ required: true, message: '请填写重量', trigger: 'blur' }],
                cargo_volume_per: [{ required: true, message: '请填写体积', trigger: 'blur' }],
                normal_freeze:[{required: true, message: '请选择冷箱标志', trigger: 'change' }]
            },
            SentManifestList: [],
            page:1,
            per_page:10,
            currentPage:1,
            count: 0,
            bl_no: '',
            time: '',
            vessel: '',
            draftList: [],//草稿列表
            draftListShow: true,
            save: true,// 是否允许保存草稿(已发送不允许保存草稿)
            autoComplete: false,//自动补全
            autoCompleteMsg: {
                bl_no:''//EGLV142100738631
            },
            autoCompleteRules: {
                bl_no: [{required: true, message: '请填写提单号', trigger: 'blur'}]
            },
            shipping_agency_list: [
                { label: '顺德船代', value: '560100333' },
                { label: '华港船代', value: '13453884-2' },
                { label: '航华船代', value: '150016700' },
                { label: '上海中外运', value: '132210843' },
                { label: '联合船代', value: '133747781' },
                { label: '鹏海船代', value: '717859429' },
                { label: '鹏华船代', value: '71785140-0' },
                { label: '上海外代', value: '132208081' },
                { label: '新海船代', value: '133718147' },
                { label: '民生船代', value: '132230158' },
                { label: '中联船代(原中和船代)', value: 'MA1G5DJT4' },
                { label: '联东船代', value: '717883154' },
            ]
        }
    },
    created() {
        if(!localStorage.getItem('DDTtoken')){
            this.$alert('您还未登录，请登录', {
                confirmButtonText: '确定',
                callback:action => {
                    if(action == 'confirm'){
                         this.$router.push('/login')
                    }else {
                        this.$message({
                            type: 'info',
                            message: "取消",
                            center:true,
                            showClose: true,
                        });
                        this.$router.push('/')
                    }
                }
            });
        } else {
            var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
            if (decoded.status == 0) {
                this.$alert('您的账户正在审核中，审核通过即可使用舱单功能', {
                    confirmButtonText: '确定',
                    callback: action => {
                        if (action == 'confirm') {
                            this.$router.push('/')
                        } else {
                            this.$message({
                                type: 'info',
                                message: "取消",
                                center: true,
                                showClose: true,
                            });
                            this.$router.push('/')
                        }
                    }
                });
            } else {
                this.SentManifestSearch('A') 
            }
        }
    },
    methods:{
        changeTab(val){
            this.tabShow = val
            this.page = 1
            this.per_page = 10
            this.reset()
            if (val == '1') {
                this.SentManifestSearch('A')
            }else if (val == '2') {
                this.manifestMsg = {
                    cargos: [],
                    containers: []
                }
                this.SentManifestSearch('S')
            }
            
        },
        // 自动补充
        autoCompleteSeach() { 
            this.$refs.autoCompleteMsg.validate(valid => {
                if (valid) { 
                    axios.get(this.GLOBAL.BASE_URL1 + '/ContainerInfo/QueryOne', {
                        params: {
                            blNo: this.autoCompleteMsg.bl_no,
                            FILETYPE: 'ALL',
                            ie:'E'
                        }
                    })
                    .then(res => {
                        console.log(res);
                        if (res.data.status == '1' && res.data.billno.length>0) {
                            this.commonCdReload(res.data)
                        } else {
                            this.$message({
                                type: 'warning',
                                message: '数据查询失败',
                                showClose: true,
                                center: true
                            });
                        }
                        this.autoComplete = false
                    })
                    .catch(error => {
                        console.log(error);
                    })
                }
            })
           
        },
        // 自动填充数据处理
        commonCdReload(data) { 
            console.log(data);
            var chuandai = data.schedule ? data.schedule[0].carrier_agent_brief.substring(0, 2) : ''
            var agency = ''
            this.shipping_agency_list.forEach(item => {
                if (item.label.indexOf(chuandai) >= 0) {
                    agency = item.value
                }
            })
            this.manifestMsg = {
                shipping_agency:agency,
                bl_no: this.autoCompleteMsg.bl_no,
                // carrier: data.schedule ? data.schedule[0].carrier_code.substr(1) : '',
                start_port_code: data.billno[0].loading_port || '',
                start_port: data.billno[0].loading_port_info ? data.billno[0].loading_port_info[1] : '',
                end_port_code: data.billno[0].destination_port || '',
                end_port: data.billno[0].destination_port_info ? data.billno[0].destination_port_info[1] : '',
                transfer_port_code: data.billno[0].discharging_port || '',
                transfer_port: data.billno[0].discharging_port_info ? data.billno[0].discharging_port_info[1] : '',
                place_code_of_receipt: data.billno[0].destination_port || '',
                place_of_receipt: data.billno[0].destination_port_info ? data.billno[0].destination_port_info[1] : '',
                load_port_code: data.billno[0].loading_port || '',
                load_port: data.billno[0].loading_port_info ? data.billno[0].loading_port_info[1] : '',
                discharge_port_code: data.billno[0].discharging_port || '',
                discharge_port: data.billno[0].discharging_port_info ? data.billno[0].discharging_port_info[1] : '',
                vessel: data.schedule ? data.schedule[0].vessel_en : '',
                voyage: data.schedule ? data.schedule[0].export_voyage:'',
                cargos: [],
                containers: []
            }
            data.billno.forEach(item => {
                var cargosData = {
                    cargo_num: item.points_number,
                    cargo_weight: item.points_weight,
                    cargo_volume: item.partial_volume,
                    packaging_tare: item.box_gross_weight
                }
                var containersData = {
                    container_type: item.box_type,
                    container_no: item.carton_number,
                    seal_no: item.seal_number,
                    cargo_num_per: item.points_number,
                    cargo_weight_per: item.points_weight,
                    cargo_volume_per: item.partial_volume,
                }
                this.manifestMsg.cargos.push(cargosData)
                this.manifestMsg.containers.push(containersData)
            });
        },
        // 船公司模糊查询
        secrchCarrier(val) {
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/carrier/getCode',{
                params:{
                    carrier_name: val
                }
            })
            .then(res => {
                if(res.data.status_code == 0){
                    if(res.data.carriers.length != 0){
                        this.carrierList = res.data.carriers
                    }else {
                        this.carrierList = []
                    }
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 添加货物信息
        addCargosItem() {
            this.cargosItemVisible = true
            this.saveCargos = false
            this.cargosItem = {}
        },
        // 货物信息弹窗确定
        sureCargosItem() {
            this.$refs.cargosItem.validate(valid => {
                if (valid) {
                    this.cargosItem.cargo_num = parseInt(this.cargosItem.cargo_num)
                    this.cargosItem.cargo_weight = parseFloat(this.cargosItem.cargo_weight)
                    this.cargosItem.cargo_volume = parseFloat(this.cargosItem.cargo_volume)
                    this.cargosItem.packaging_tare = parseFloat(this.cargosItem.packaging_tare)
                    this.manifestMsg.cargos.push(this.cargosItem)
                    this.cargosItemVisible = false
                    this.cargosItem = {}
                }
            })
        },
        // 货物信息-编辑
        auitCargos(index, data) {
            this.saveCargos = true
            this.cargosItemVisible = true
            this.cargosItem = data
            this.saveCargosIdx = index
        },
        // 货物信息弹框保存
        saveCargosItem() {
            this.$refs.cargosItem.validate(valid => {
                if (valid) {
                    this.manifestMsg.cargos.splice(this.saveCargosIdx,1,this.cargosItem)
                    this.cargosItemVisible = false
                    this.cargosItem = {}
                }
            })
        },
        // 删除
        del(index, data) {
            this.$confirm('删除后数据无法恢复, 确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
              }).then(() => {
                data.splice(index, 1);
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                  showClose: true,
                  center: true
                });
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除',
                  showClose: true,
                  center: true
                });
            });
        },
        // 箱货信息
        addContainersItem() {
            this.containersItemVisible = true
            this.saveContainers = false
            this.containersItem = {}
        },
        // 箱货弹框确定
        surecontainersItem() {
            this.$refs.containersItem.validate(valid => {
                if (valid) {
                    this.containersItem.cargo_num_per = parseInt(this.containersItem.cargo_num_per)
                    this.containersItem.cargo_weight_per = parseFloat(this.containersItem.cargo_weight_per)
                    this.containersItem.cargo_volume_per = parseFloat(this.containersItem.cargo_volume_per)
                    this.manifestMsg.containers.push(this.containersItem)
                    this.containersItemVisible = false
                    this.containersItem = {}
                }
            })
        },
        // 箱货弹框保存
        savecontainersItem() {
            this.$refs.containersItem.validate(valid => {
                if (valid) {
                    this.manifestMsg.containers.splice(this.saveContainersIdx,1,this.containersItem)
                    this.containersItemVisible = false
                    this.containersItem = {}
                }
            })
        },
        // 行内编辑
        auitContainers(index, data) {
            this.saveContainers = true
            this.containersItemVisible = true
            this.containersItem = data
            this.saveContainersIdx = index
        },
        // 发送舱单/保存草稿
        send(state) {
            this.$refs.manifestMsg.validate(valid => {
                if (valid) {
                    if (this.manifestMsg.cargos.length == 0) {
                        this.$message({
                            type: 'warning',
                            message: '请填写货物信息',
                            showClose: true,
                            center: true
                        });
                    } else if (this.manifestMsg.containers.length == 0) {
                        this.$message({
                            type: 'warning',
                            message: '请填写箱货信息',
                            showClose: true,
                            center: true
                        }); 
                    } else {
                        if (state == 'S') {
                            this.manifestMsg.msg_status ='S'
                        } else if (state == 'A') {
                            if (this.manifestMsg.id) {
                                this.manifestMsg.msg_status = 'R'
                            } else {
                                this.manifestMsg.msg_status = 'A'
                            }
                        }
                        axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                        axios.defaults.headers.common['Content-Type'] = 'application/json'
                        axios.post(this.GLOBAL.BASE_URL1 + '/yitong_iftcps/send', this.manifestMsg)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: res.data.message,
                                    showClose: true,
                                    center: true
                                });
                                this.manifestMsg = {
                                    cargos: [],
                                    containers: []
                                }
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.data.message,
                                    showClose: true,
                                    center: true
                                }); 
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                    }
                }
            })
        },
        // 草稿删除
        draftDelete(id) {
            this.$confirm('删除后数据无法恢复, 确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
              }).then(() => {
                axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                axios.defaults.headers.common['Content-Type'] = 'application/json'
                axios.post(this.GLOBAL.BASE_URL1 + '/yitong_iftcps/send', {
                    id: id,
                    msg_status:'D'
                })
                .then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        });
                        this.SentManifestSearch('A')
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            showClose: true,
                            center: true
                        }); 
                    }
                })
                .catch(error => {
                    console.log(error);
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除',
                  showClose: true,
                  center: true
                });
              });
        },
        // 关闭在线新增
        closeMnifestMsg() {
            this.draftListShow = true
            this.manifestMsg = {
                cargos: [],
                containers: []
            }
            this.SentManifestSearch('A')
        },
        // 查询
        SentManifestSearch(state,paging) {
            var data = {
                per_page: this.per_page,
                page:this.page
            }
            if (paging) {
                data.per_page = null
                data.page = null
            }
            if (state == 'A') {
                data.state = 'A' //待发送
            } else if(state == 'S'){
                data.state = 'S'
            }
            if (this.bl_no) {
                data.bl_no = this.bl_no
            }
            if (this.vessel) {
                data.vessel = this.vessel
            }
            if (this.time) {
                data.start_time = this.time[0]
                data.end_time = this.time[1]
            }
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/yitong_iftcps/send', {
                params: data
            })
            .then(res => {
                if (res.data.status_code == 200) {
                    if (state == 'A') {
                        // 草稿
                        this.draftList = res.data.data
                    } else {
                        // 已发送
                        this.SentManifestList = res.data.data
                    }
                    this.count = res.data.count
                } else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    }); 
                }
            })
            .catch(error => {
                console.log(error);
            })
        },
        handleSizeChange(val) {
            this.per_page = val
            this.SentManifestSearch()
        },
        handleCurrentChange(val) {
            this.page = val
            this.SentManifestSearch()
        },
        // 重置
        reset() {
            this.bl_no = ''
            this.vessel = ''
            this.time = ''
        },
        // 查看详情
        toDetial(val,sign) {
            this.tabShow = '1'
            this.draftListShow = false
            this.manifestMsg = val
            this.shipperType = val.shipper_tel ? 'tel' : val.shipper_fax ? 'fax' : 'mail'
            this.consigneeType = val.consignee_tel ? 'tel' : val.consignee_fax ? 'fax' : 'mail'
            this.consignee_contactType = val.consignee_contact_tel ? 'tel' : val.consignee_contact_fax ? 'fax' : 'mail'
            this.notifierType = val.notifier_tel ? 'tel' : val.notifier_fax ? 'fax' : 'mail'
            if (sign == 'sent') {
                this.save = false
            }
            this.page = 1
            this.per_page = 10
            this.reset()
        },
    }
}