<template>
  <div class="Manifest">
      <div class="asideMan">
        <el-menu
            :default-active="tabShow"
            background-color="#fff"
            text-color="#F0801B"
            active-text-color="#F0801B">
            <el-menu-item index="1"  @click="changeTab('1')">
                 <i class="el-icon-takeaway-box"></i>
                <span slot="title">舱单草稿箱</span>
            </el-menu-item>
            <el-menu-item index="2" @click="changeTab('2')">
                <i class="el-icon-position"></i>
                <span slot="title">已发送舱单</span>
            </el-menu-item>
        </el-menu>
      </div>
      <div class="manifest-main" v-show="tabShow == '1'">
          <div class="draft" v-show="draftListShow">
                <div class="draft-head">
                    <el-row :gutter="20">
                        <el-col :span="6" :offset="6">
                            <div class="grid-content">
                                <button><i class="el-icon-folder-add"></i> 表格导入</button>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="grid-content">
                                <button @click="draftListShow = false;save=true;manifestMsg = { cargos: [], containers: []}"><i class="el-icon-edit-outline"></i> 在线新增</button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="draft-cont">
                    <div class="sentListTit">
                        <el-row :gutter="10">
                            <el-col :xs="6" :sm="6" :md="2" :lg="2" :xl="2">
                                <label for="">提单号:</label>
                            </el-col>
                            <el-col :xs="18" :sm="18" :md="10" :lg="4" :xl="4">
                                <el-input v-model="bl_no" placeholder=""></el-input>
                            </el-col>
                            <el-col :xs="6" :sm="6" :md="2" :lg="2" :xl="2">
                                <label for="">船名:</label>
                            </el-col>
                            <el-col :xs="18" :sm="18" :md="10" :lg="4" :xl="4">
                                <el-input v-model="vessel" placeholder=""></el-input>
                            </el-col>
                            <el-col :xs="6" :sm="6" :md="2" :lg="2" :xl="2">
                                <label for="">发送时间:</label>
                            </el-col>
                            <el-col :xs="18" :sm="18" :md="10" :lg="6" :xl="5">
                                <el-date-picker
                                    v-model="time"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :default-time="['00:00:00','23:59:59']"
                                    >
                                    </el-date-picker>
                            </el-col>
                            <el-col :xs="18" :sm="8" :md="6" :lg="4" :xl="5">
                                <div class="sentListTitBtn">
                                    <el-button type="success" @click="SentManifestSearch('A','paging')">查询</el-button>
                                    <el-button type="warning" @click="reset()">重置</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <el-table
                        :data="draftList"
                        border
                        style="width: 100%">
                        <el-table-column
                            prop="eb_no"
                            label="订舱编号"
                            width="160"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="bl_no"
                            label="提单号"
                            width="160"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="start_port"
                            label="起运港"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="end_port"
                            label="目的港"
                            width="250">
                        </el-table-column>
                        <el-table-column
                            prop="transfer_port"
                            label="中转港"
                            width="250">
                        </el-table-column>
                        <el-table-column
                            prop="vessel"
                            label="船名"
                            width="250">
                        </el-table-column>
                        <el-table-column
                            prop="voyage"
                            label="航次"
                            width="150"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="update_time"
                            label="发送时间"
                            width="160">
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            >
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="toDetial(scope.row)">编辑</el-button>
                                <el-button
                                    size="mini"
                                    type="danger"
                                    @click="draftDelete(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                        <div slot="empty" class="emptyBg">
                            <img src="../../assets/empty.png" alt="">
                            <p>没有记录哦~</p>
                        </div>
                    </el-table>
                    <div class="sentListPage">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 30, 40, 50, 100]"
                            :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="count">
                        </el-pagination>
                    </div>
                </div>
           </div>
           <div class="auit" v-show="!draftListShow">
                <el-divider></el-divider>
                <div class="manifest-btn">
                    <el-button type="success" @click="send('S')">一键发送舱单</el-button>
                    <el-button type="success" v-show="save" @click="send('A')" plain>保存草稿</el-button>
                    <el-button type="warning" @click="autoComplete = true">自动填充数据</el-button>
                    <el-button type="info" plain @click="closeMnifestMsg()">关闭</el-button>
                </div>
                <el-form :model="manifestMsg" :rules="rules" ref="manifestMsg" label-width="100px" class="demo-ruleForm">
                    <el-divider content-position="left">基础信息</el-divider>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="船代" prop="shipping_agency">
                                <el-select v-model="manifestMsg.shipping_agency">
                                    <el-option v-for="(item,index) in shipping_agency_list" :key="index" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="订舱编号" prop="eb_no">
                                <el-input v-model="manifestMsg.eb_no" @input="manifestMsg.eb_no=manifestMsg.eb_no.toUpperCase()"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="提单号" prop="bl_no">
                                <el-input v-model="manifestMsg.bl_no"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="船公司" prop="carrier">
                                <el-select v-model="manifestMsg.carrier" 
                                    filterable 
                                    remote
                                    :remote-method="secrchCarrier"
                                    placeholder="例如：‘ONE’"
                                    >
                                    <el-option
                                    v-for="item in carrierList"
                                    :key="item.id"
                                    :label="item.nameEn"
                                    :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="起运港代码" prop="start_port_code">
                                <el-input v-model="manifestMsg.start_port_code"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="起运港" prop="start_port">
                                <el-input v-model="manifestMsg.start_port"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="目的港代码" prop="end_port_code">
                                <el-input v-model="manifestMsg.end_port_code"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="目的港" prop="end_port">
                                <el-input v-model="manifestMsg.end_port"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="中转港代码" prop="transfer_port_code">
                                <el-input v-model="manifestMsg.transfer_port_code"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="中转港" prop="transfer_port">
                                <el-input v-model="manifestMsg.transfer_port"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="收货地代码" prop="place_code_of_receipt">
                                <el-input v-model="manifestMsg.place_code_of_receipt"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="收货地" prop="place_of_receipt">
                                <el-input v-model="manifestMsg.place_of_receipt"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="装货地代码" prop="load_port_code">
                                <el-input v-model="manifestMsg.load_port_code"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="装货地" prop="load_port">
                                <el-input v-model="manifestMsg.load_port"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="卸货港代码" prop="discharge_port_code">
                                <el-input v-model="manifestMsg.discharge_port_code"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="卸货地" prop="discharge_port">
                                <el-input v-model="manifestMsg.discharge_port"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="船名" prop="vessel">
                                <el-input v-model="manifestMsg.vessel"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="航次" prop="voyage">
                                <el-input v-model="manifestMsg.voyage"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                            <el-form-item label="放单类型" prop="put_type">
                                <el-select v-model="manifestMsg.put_type">
                                    <el-option label="ORIGINAL" value="ORI"></el-option>
                                    <el-option label="SEA WAYBILL" value="SWB"></el-option>
                                    <el-option label="SURRENDERED" value="SUR"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="运费条款" prop="freight_terms">
                                <el-select v-model="manifestMsg.freight_terms">
                                    <el-option label="PREPAID" value="PP"></el-option>
                                    <el-option label="COLLECT" value="CC"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">  
                            <el-form-item label="运输条款" prop="transport_terms">
                                <el-select v-model="manifestMsg.transport_terms">
                                    <el-option label="CY-CY" value="CY-CY"></el-option>
                                    <el-option label="CY-CFS" value="CY-CFS"></el-option>
                                    <el-option label="CFS-CY" value="CFS-CY"></el-option>
                                    <el-option label="CFS-CFS" value="CFS-CFS"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left">收发通</el-divider>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">  
                            <el-form-item label="发货人" prop="shipper_name">
                                <el-input v-model="manifestMsg.shipper_name"></el-input>
                            </el-form-item>
                            <el-form-item label="地址" prop="shipper_addr">
                                <el-input type="textarea" v-model="manifestMsg.shipper_addr"></el-input>
                            </el-form-item>
                            <el-form-item label="企业代码" prop="shipper_code">
                                <el-input v-model="manifestMsg.shipper_code"></el-input>
                            </el-form-item>
                            <el-form-item label="国家代码" prop="shipper_country_code">
                                <el-input v-model="manifestMsg.shipper_country_code" maxlength="2"></el-input>
                            </el-form-item>
                            <el-form-item label="AEO代码" prop="shipper_aeo">
                                <el-input v-model="manifestMsg.shipper_aeo"></el-input>
                            </el-form-item>
                            <el-form-item label="联系类型">
                                <el-select v-model="shipperType">
                                    <el-option label="电话" value="tel"></el-option>
                                    <el-option label="传真" value="fax"></el-option>
                                    <el-option label="邮箱" value="mail"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-show="shipperType == 'tel'" label="电话" prop="shipper_tel" :rules="shipperType == 'tel' ? rules.shipper_tel : []">
                                <el-input v-model="manifestMsg.shipper_tel"></el-input>
                            </el-form-item>
                            <el-form-item v-show="shipperType == 'fax'" label="传真" prop="shipper_fax" :rules="shipperType == 'fax' ? rules.shipper_fax : []">
                                <el-input v-model="manifestMsg.shipper_fax"></el-input>
                            </el-form-item>
                            <el-form-item v-show="shipperType == 'mail'" label="邮箱" prop="shipper_mail" :rules="shipperType == 'mail' ? rules.shipper_mail : []">
                                <el-input v-model="manifestMsg.shipper_mail"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">  
                            <el-form-item label="收货人" prop="consignee_name">
                                <el-input v-model="manifestMsg.consignee_name"></el-input>
                            </el-form-item>
                            <el-form-item label="地址" prop="consignee_addr">
                                <el-input type="textarea" v-model="manifestMsg.consignee_addr"></el-input>
                            </el-form-item>
                            <el-form-item label="企业代码" prop="consignee_code">
                                <el-input v-model="manifestMsg.consignee_code"></el-input>
                            </el-form-item>
                            <el-form-item label="国家代码" prop="consignee_country_code">
                                <el-input v-model="manifestMsg.consignee_country_code" maxlength="2"></el-input>
                            </el-form-item>
                            <el-form-item label="AEO代码" prop="consignee_aeo">
                                <el-input v-model="manifestMsg.consignee_aeo"></el-input>
                            </el-form-item>
                            <el-form-item label="联系类型">
                                <el-select v-model="consigneeType">
                                    <el-option label="电话" value="tel"></el-option>
                                    <el-option label="传真" value="fax"></el-option>
                                    <el-option label="邮箱" value="mail"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-show="consigneeType == 'tel'" label="电话" prop="consignee_tel" :rules="consigneeType == 'tel' ? rules.consignee_tel : []">
                                <el-input v-model="manifestMsg.consignee_tel"></el-input>
                            </el-form-item>
                            <el-form-item v-show="consigneeType == 'fax'" label="传真" prop="consignee_fax" :rules="consigneeType == 'fax' ? rules.consignee_fax : []">
                                <el-input v-model="manifestMsg.consignee_fax"></el-input>
                            </el-form-item>
                            <el-form-item v-show="consigneeType == 'mail'" label="邮箱" prop="consignee_mail" :rules="consigneeType == 'mail' ? rules.consignee_mail : []">
                                <el-input v-model="manifestMsg.consignee_mail"></el-input>
                            </el-form-item>
                            <el-form-item label="具体收货人" prop="consignee_contact_name">
                                <el-input v-model="manifestMsg.consignee_contact_name"></el-input>
                            </el-form-item>
                            <el-form-item label="具体联系类型" prop="" v-show="manifestMsg.consignee_contact_name">
                                <el-select v-model="consignee_contactType">
                                    <el-option label="电话" value="tel"></el-option>
                                    <el-option label="传真" value="fax"></el-option>
                                    <el-option label="邮箱" value="mail"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-show="consignee_contactType == 'tel' && manifestMsg.consignee_contact_name" label="具体电话" prop="consignee_contact_tel" :rules="consignee_contactType == 'tel' && manifestMsg.consignee_contact_name ? rules.consignee_contact_tel : []">
                                <el-input v-model="manifestMsg.consignee_contact_tel"></el-input>
                            </el-form-item>
                            <el-form-item v-show="consignee_contactType == 'mail' && manifestMsg.consignee_contact_name" label="具体邮箱" prop="consignee_contact_email" :rules="consignee_contactType == 'mail' && manifestMsg.consignee_contact_name? rules.consignee_contact_email : []">
                                <el-input v-model="manifestMsg.consignee_contact_email"></el-input>
                            </el-form-item>
                            <el-form-item v-show="consignee_contactType == 'fax' && manifestMsg.consignee_contact_name" label="具体传真" prop="consignee_contact_fax" :rules="consignee_contactType == 'fax' && manifestMsg.consignee_contact_name? rules.consignee_contact_fax : []">
                                <el-input v-model="manifestMsg.consignee_contact_fax"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">  
                            <el-form-item label="通知人" prop="notifier_name">
                                <el-input v-model="manifestMsg.notifier_name"></el-input>
                            </el-form-item>
                            <el-form-item label="地址" prop="notifier_addr">
                                <el-input type="textarea" v-model="manifestMsg.notifier_addr"></el-input>
                            </el-form-item>
                            <el-form-item label="企业代码" prop="notifier_code">
                                <el-input v-model="manifestMsg.notifier_code"></el-input>
                            </el-form-item>
                            <el-form-item label="国家代码" prop="notifier_country_code" >
                                <el-input v-model="manifestMsg.notifier_country_code" maxlength="2"></el-input>
                            </el-form-item>
                            <el-form-item label="具体联系类型" prop="">
                                <el-select v-model="notifierType">
                                    <el-option label="电话" value="tel"></el-option>
                                    <el-option label="传真" value="fax"></el-option>
                                    <el-option label="邮箱" value="mail"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-show="notifierType == 'tel'" label="电话" prop="notifier_tel" :rules="notifierType == 'tel' ? rules.notifier_tel : []">
                                <el-input v-model="manifestMsg.notifier_tel"></el-input>
                            </el-form-item>
                            <el-form-item v-show="notifierType == 'fax'" label="传真" prop="notifier_fax" :rules="notifierType == 'fax' ? rules.notifier_fax : []">
                                <el-input v-model="manifestMsg.notifier_fax"></el-input>
                            </el-form-item>
                            <el-form-item v-show="notifierType == 'mail'" label="邮箱" prop="notifier_email" :rules="notifierType == 'mail' ? rules.notifier_email : []">
                                <el-input v-model="manifestMsg.notifier_email"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider content-position="left">货物信息</el-divider>
                    <div class="cargosBtn">
                        <el-button type="warning" @click="addCargosItem()">添加货物信息</el-button>
                        <el-dialog
                            title="货物信息"
                            :visible.sync="cargosItemVisible"
                            width="50%">
                            <span>
                                <el-form :model="cargosItem" :rules="cargosItemRules" ref="cargosItem" label-width="100px" class="demo-ruleForm">
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="货物标识" prop="cargo_type">
                                                <el-select v-model="cargosItem.cargo_type">
                                                    <el-option label="普通" value="S"></el-option>
                                                    <el-option label="冷冻" value="R"></el-option>
                                                    <el-option label="危险" value="D"></el-option>
                                                    <el-option label="非标" value="O"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="货物代码">
                                                <el-input v-model="cargosItem.cargo_code"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="货物数量" prop="cargo_num">
                                                <el-input v-model="cargosItem.cargo_num"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="货物毛重" prop="cargo_weight">
                                                <el-input v-model="cargosItem.cargo_weight"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="货物体积" prop="cargo_volume">
                                                <el-input v-model="cargosItem.cargo_volume"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="包装类型" prop="cargo_pack">
                                                <el-select v-model="cargosItem.cargo_pack">
                                                    <el-option label="BAGS" value="BAGS"></el-option>
                                                    <el-option label="BALES" value="BALES"></el-option>
                                                    <el-option label="BARES" value="BARES"></el-option>
                                                    <el-option label="BUNDLES" value="BUNDLES"></el-option>
                                                    <el-option label="BOTTLES" value="BOTTLES"></el-option>
                                                    <el-option label="BOXS" value="BOXS"></el-option>
                                                    <el-option label="BULKS" value="BULKS"></el-option>
                                                    <el-option label="CASES" value="CASES"></el-option>
                                                    <el-option label="CASKS" value="CASKS"></el-option>
                                                    <el-option label="CANS" value="CANS"></el-option>
                                                    <el-option label="COILS" value="COILS"></el-option>
                                                    <el-option label="CRATES" value="CRATES"></el-option>
                                                    <el-option label="CYLINDERS" value="CYLINDERS"></el-option>
                                                    <el-option label="CARTONS" value="CARTONS"></el-option>
                                                    <el-option label="BARES" value="BARES"></el-option>
                                                    <el-option label="PANELS" value="PANELS"></el-option>
                                                    <el-option label="PACKAGES" value="PACKAGES"></el-option>
                                                    <el-option label="PACKS" value="PACKS"></el-option>
                                                    <el-option label="PALLETS" value="PALLETS"></el-option>
                                                    <el-option label="FRAMES" value="FRAMES"></el-option>
                                                    <el-option label="RACKS" value="RACKS"></el-option>
                                                    <el-option label="ROLLS" value="ROLLS"></el-option>
                                                    <el-option label="ISOTANK" value="ISOTANK"></el-option>
                                                    <el-option label="IBC" value="IBC"></el-option>
                                                    <el-option label="SACKS" value="SACKS"></el-option>
                                                    <el-option label="SET" value="SET"></el-option>
                                                    <el-option label="SHEETS" value="SHEETS"></el-option>
                                                    <el-option label="TANK" value="TANK"></el-option>
                                                    <el-option label="UNITS" value="UNITS"></el-option>
                                                    <el-option label="WOODEN CASES" value="WOODEN CASES"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="货物品名" prop="cargo_name">
                                                <el-input type="textarea" v-model="cargosItem.cargo_name"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="HSCode">
                                                <el-input v-model="cargosItem.hscode"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="唛头" prop="marks">
                                                <el-input type="textarea" v-model="cargosItem.marks"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="包装皮重" prop="packaging_tare">
                                                <el-input v-model="cargosItem.packaging_tare"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="防疫代码">
                                                <el-input v-model="cargosItem.quarantine_code"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="防疫名称">
                                                <el-input v-model="cargosItem.quarantine_name"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item v-show="cargosItem.cargo_type == 'D'" label="危险品分类" prop="danger_class" :rules="cargosItem.cargo_type == 'D' ? cargosItemRules.danger_class : []">
                                                <el-input v-model="cargosItem.danger_class"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item v-show="cargosItem.cargo_type == 'D'" label="危险品编号" prop="danger_no" :rules="cargosItem.cargo_type == 'D' ? cargosItemRules.danger_no : []">
                                                <el-input v-model="cargosItem.danger_no"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item v-show="cargosItem.cargo_type == 'D'" label="危险品页号" prop="danger_page" :rules="cargosItem.cargo_type == 'D' ? cargosItemRules.danger_page : []">
                                                <el-input v-model="cargosItem.danger_page"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item v-show="cargosItem.cargo_type == 'D'" label="危险品闪点" prop="danger_flashpoint" :rules="cargosItem.cargo_type == 'D' ? cargosItemRules.danger_flashpoint : []">
                                                <el-input v-model="cargosItem.danger_flashpoint"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item v-show="cargosItem.cargo_type == 'D'" label="包装类别" prop="packing_group">
                                                <el-input v-model="cargosItem.packing_group"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item v-show="cargosItem.cargo_type == 'D'" label="紧急联系人" prop="danger_phone">
                                                <el-input v-model="cargosItem.danger_phone"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </span>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="cargosItemVisible = false">取 消</el-button>
                                <el-button type="primary" v-show="!saveCargos" @click="sureCargosItem()">确 定</el-button>
                                <el-button type="primary" v-show="saveCargos" @click="saveCargosItem()">保 存</el-button>
                            </span>
                        </el-dialog>
                    </div>
                    <div class="cargosMain">
                        <el-table
                            :data="manifestMsg.cargos"
                            border
                            style="width: 100%">
                            <el-table-column
                                prop="cargo_type"
                                label="货物标识"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.cargo_type == 'S' ? '普通' : scope.row.cargo_type == 'R' ? '冷冻' : scope.row.cargo_type == 'D' ? '危险' : scope.row.cargo_type == 'O' ? '非标' : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="cargo_code"
                                label="货类代码"
                                width="">
                            </el-table-column>
                            <el-table-column
                                prop="cargo_num"
                                label="货物数量">
                            </el-table-column>
                            <el-table-column
                                prop="cargo_weight"
                                label="货物毛重">
                            </el-table-column>
                            <el-table-column
                                prop="cargo_volume"
                                label="货物体积">
                            </el-table-column>
                            <el-table-column
                                prop="cargo_pack"
                                label="包装类型">
                            </el-table-column>
                            <el-table-column
                                prop="packaging_tare"
                                label="包装皮重">
                            </el-table-column>
                            <el-table-column
                                label="操作"
                                >
                                <template slot-scope="scope">
                                    <el-button
                                        size="mini"
                                        type="primary"
                                        @click="auitCargos(scope.$index, scope.row)"
                                        >编辑</el-button>
                                    <el-button
                                        size="mini"
                                        type="danger"
                                        @click="del(scope.$index, manifestMsg.cargos)"
                                        >删除</el-button>
                                </template>
                            </el-table-column>
                            <div slot="empty" class="emptyBg">
                                <img src="../../assets/empty.png" alt="">
                                <p>没有记录哦~</p>
                            </div>
                        </el-table>
                    </div>
                    <el-divider content-position="left">箱货信息</el-divider>
                    <div class="containersBtn">
                        <el-button type="warning" @click="addContainersItem()">添加箱货信息</el-button>
                        <el-dialog
                            title="箱货信息"
                            :visible.sync="containersItemVisible"
                            width="50%">
                            <span>
                                <el-form :model="containersItem" :rules="containersItemRules" ref="containersItem" label-width="100px" class="demo-ruleForm">
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="箱型" prop="container_type">
                                                <el-select v-model="containersItem.container_type">
                                                    <el-option label="20GP" value="20GP"></el-option>
                                                    <el-option label="40GP" value="40GP"></el-option>
                                                    <el-option label="40HQ" value="40HQ"></el-option>
                                                    <el-option label="20FR" value="20FR"></el-option>
                                                    <el-option label="40FR" value="40FR"></el-option>
                                                    <el-option label="20RF" value="20RF"></el-option>
                                                    <el-option label="40RF" value="40RF"></el-option>
                                                    <el-option label="20OT" value="20OT"></el-option>
                                                    <el-option label="40OT" value="40OT"></el-option>
                                                    <el-option label="40OH" value="40OH"></el-option>
                                                    <el-option label="20TK" value="20TK"></el-option>
                                                    <el-option label="40TK" value="40TK"></el-option>
                                                    <el-option label="20HT" value="20HT"></el-option>
                                                    <el-option label="40HT" value="40HT"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="集装箱标识" prop="container_mark">
                                                <el-select v-model="containersItem.container_mark">
                                                    <el-option label="整箱" value="F"></el-option>
                                                    <el-option label="拼箱" value="L"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="货主箱标识" prop="container_soc">
                                                <el-select v-model="containersItem.container_soc">
                                                    <el-option v-for="(item,index) in container_soc_list" :key="index" :label="item.label" :value="item.value"></el-option>
                                                </el-select>
                                            </el-form-item>
                                    </el-col>
                                    </el-row>
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="箱号" prop="container_no">
                                                <el-input v-model="containersItem.container_no"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="铅封号" prop="seal_no">
                                                <el-input v-model="containersItem.seal_no"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="数量" prop="cargo_num_per">
                                                <el-input v-model="containersItem.cargo_num_per"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="10">
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="重量" prop="cargo_weight_per">
                                                <el-input v-model="containersItem.cargo_weight_per"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="体积" prop="cargo_volume_per">
                                                <el-input  v-model="containersItem.cargo_volume_per"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                            <el-form-item label="冷箱标志" prop="normal_freeze">
                                                <el-select v-model="containersItem.normal_freeze">
                                                    <el-option label="冷箱" value="Y"></el-option>
                                                    <el-option label="非冷箱" value="N"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </span>
                            <span slot="footer" class="dialog-footer">
                                <el-button @click="containersItemVisible = false">取 消</el-button>
                                <el-button type="primary" v-show="!saveContainers" @click="surecontainersItem()">确 定</el-button>
                                <el-button type="primary" v-show="saveContainers" @click="savecontainersItem()">保 存</el-button>
                            </span>
                        </el-dialog>
                    </div>
                    <div class="containersMain">
                        <el-table
                            :data="manifestMsg.containers"
                            border
                            style="width: 100%">
                            <el-table-column
                                prop="container_type"
                                label="箱型"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="container_mark"
                                label="集装箱标识"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.container_mark == 'F' ? '整箱' : scope.row.container_mark == 'L' ? '拼箱' : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="container_soc"
                                label="货主箱标识">
                                <template slot-scope="scope">
                                    {{scope.row.container_soc == true ? '货主箱' : scope.row.container_soc == false ? '非货主箱' : scope.row.container_soc}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="container_no"
                                label="箱号">
                            </el-table-column>
                            <el-table-column
                                prop="seal_no"
                                label="铅封号">
                            </el-table-column>
                            <el-table-column
                                prop="cargo_num_per"
                                label="货物数量">
                            </el-table-column>
                            <el-table-column
                                prop="cargo_weight_per"
                                label="货物重量">
                            </el-table-column>
                            <el-table-column
                                prop="cargo_volume_per"
                                label="货物体积">
                            </el-table-column>
                            <el-table-column
                                prop="normal_freeze"
                                label="冷箱标志">
                                <template slot-scope="scope">
                                    {{scope.row.normal_freeze == 'Y' ? '冷箱' : scope.row.normal_freeze == 'N' ? '非冷箱' : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="操作"
                                >
                                <template slot-scope="scope">
                                    <el-button
                                        size="mini"
                                        type="primary"
                                        @click="auitContainers(scope.$index, scope.row)"
                                        >编辑</el-button>
                                    <el-button
                                        size="mini"
                                        type="danger"
                                        @click="del(scope.$index, manifestMsg.containers)"
                                        >删除</el-button>
                                </template>
                            </el-table-column>
                            <div slot="empty" class="emptyBg">
                                <img src="../../assets/empty.png" alt="">
                                <p>没有记录哦~</p>
                            </div>
                        </el-table>
                    </div>
                </el-form>
                <!-- 自动填充数据 -->
                <el-dialog
                    :visible.sync="autoComplete"
                    title="自动填充数据"
                    width="30%"
                    v-if="autoComplete"
                    >
                    <el-form :model="autoCompleteMsg" :rules="autoCompleteRules" ref="autoCompleteMsg" label-width="100px">
                        <el-form-item label="提单号" prop="bl_no">
                            <el-input v-model="autoCompleteMsg.bl_no"></el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="autoComplete = false">取 消</el-button>
                        <el-button type="primary" @click="autoCompleteSeach">确 定</el-button>
                    </span>
                </el-dialog>
           </div>
      </div>
      <div class="manifest-main" v-show="tabShow == '2'">
        <el-divider></el-divider>
          <div class="sentListTit">
            <el-row :gutter="10">
                <el-col :xs="6" :sm="6" :md="2" :lg="2" :xl="2">
                    <label for="">提单号:</label>
                </el-col>
                <el-col :xs="18" :sm="18" :md="10" :lg="4" :xl="5">
                    <el-input v-model="bl_no" placeholder=""></el-input>
                </el-col>
                 <el-col :xs="6" :sm="6" :md="2" :lg="2" :xl="2">
                    <label for="">船名:</label>
                </el-col>
                <el-col :xs="18" :sm="18" :md="10" :lg="4" :xl="5">
                    <el-input v-model="vessel" placeholder=""></el-input>
                </el-col>
                <el-col :xs="6" :sm="6" :md="2" :lg="2" :xl="2">
                    <label for="">发送时间:</label>
                </el-col>
                <el-col :xs="18" :sm="18" :md="10" :lg="6" :xl="5">
                    <el-date-picker
                        v-model="time"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00','23:59:59']"
                        >
                        </el-date-picker>
                </el-col>
                <el-col :xs="18" :sm="8" :md="6" :lg="4" :xl="3">
                    <div class="sentListTitBtn">
                        <el-button type="success" @click="SentManifestSearch('S','paging')">查询</el-button>
                        <el-button type="warning" @click="reset()">重置</el-button>
                    </div>
                </el-col>
            </el-row>
          </div>
          <div class="sentList">
            <el-table
                :data="SentManifestList"
                border
                style="width: 100%">
                <el-table-column
                    prop="eb_no"
                    label="订舱编号"
                    width="160"
                    >
                </el-table-column>
                <el-table-column
                    prop="bl_no"
                    label="提单号"
                    width="160"
                    >
                </el-table-column>
                <el-table-column
                    prop="start_port"
                    label="起运港"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="end_port"
                    label="目的港"
                    width="250">
                </el-table-column>
                <el-table-column
                    prop="transfer_port"
                    label="中转港"
                    width="250">
                </el-table-column>
                <el-table-column
                    prop="vessel"
                    label="船名"
                    width="250">
                </el-table-column>
                <el-table-column
                    prop="voyage"
                    label="航次"
                    width="150"
                    >
                </el-table-column>
                <el-table-column
                    prop="update_time"
                    label="发送时间"
                    width="160">
                </el-table-column>
                <el-table-column
                    label="操作"
                    >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            @click="toDetial(scope.row,'sent')"
                            >详情</el-button>
                    </template>
                </el-table-column>
                <div slot="empty" class="emptyBg">
                    <img src="../../assets/empty.png" alt="">
                    <p>没有记录哦~</p>
                </div>
            </el-table>
            <div class="sentListPage">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="count">
                </el-pagination>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
    import Manifest from './Manifest.js'
    export default Manifest
</script>

<style>
    @import './Manifest.css';
</style>